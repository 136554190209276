jQuery(document).ready(function () {

    /** Sticky header on scroll */
    var header = jQuery("#header");
    jQuery(window).scroll(function () {
        var scroll = jQuery(window).scrollTop();
        if (scroll > 0) {
            header.addClass("sticky");
        } else {
            header.removeClass("sticky");
        }
    });

    /** Anchor links animated scroll */
    jQuery(document).on('click', '.scroll__link', function (event) {
        event.preventDefault();
        var elemId = jQuery(jQuery.attr(this, 'href'));
        var headerHeight = jQuery('#header').outerHeight();
        jQuery('html, body').animate({
            scrollTop: jQuery(elemId).offset().top - headerHeight
        }, 500);
    });

    /** Mobile menu */
    var $toggleMenuBtn = jQuery('.m_nav--button');
    var $menuContainer = $toggleMenuBtn.next();
    $toggleMenuBtn.on('click', function () {
        var self = jQuery(this);
        self.toggleClass('active');
        $menuContainer.toggleClass('open');
    });

    /** International phones */
    jQuery("#phone-input").intlTelInput({
        hiddenInput: "full_phone",
        utilsScript: "js/libs.js" // just for formatting/placeholders etc
    });

    jQuery("#phoneForm").submit(function (event) {
        event.preventDefault();

        $btnSubmit = jQuery('#phone-submit');

        var currentTime = Date.now();
        var startTime = JSON.parse(localStorage.getItem('startTime'));

        if (!startTime) {
            startTime = Date.now();
            localStorage.setItem('startTime', JSON.stringify(startTime));
        }

        var timeInfo = 3600 - parseInt((currentTime - startTime) / 1000);

        if (timeInfo <= 0) {
            startTime = Date.now();
            localStorage.setItem('startTime', JSON.stringify(startTime));
            localStorage.setItem('smsCounter', 0);
        }

        var counterClicks = localStorage.getItem('smsCounter');
        if (!counterClicks) {
            counterClicks = 0;
            $btnSubmit.attr("disabled", false);
        }

        var $error = jQuery('.form-actions__section').find('.error-message');
        var $success = jQuery('.form-actions__section').find('.success-message');
        if (counterClicks < 3) {

            jQuery('.sms__limit--warning').hide();

            var $form = $(this),
                term = $form.find('input[name="full_phone"]').val();

            var data = {
                "PhoneNumber": term
            };
            jQuery.ajax({
                url: 'https://4e200i3odg.execute-api.eu-central-1.amazonaws.com/dev/',
                type: 'post',
                dataType: 'json',
                contentType: 'application/json',
                data: JSON.stringify(data),
                headers: {
                    "Accept": 'application/json',   //If your header name has spaces or any other char not appropriate
                    "Content-Type": 'application/json',
                    "x-api-key": 'iiF48LD5Kx3qxvj1PhAmH8mYalXAFEJz3UwcMo4a' //for object property name, use quoted notation shown in second
                },
                success: function (data) {
                    var $form = jQuery('.sms__form');
                    if ($error) {
                        $error.remove();
                    }
                    if ($success) {
                        $success.remove();
                    }
                    if (typeof data === 'string') {
                        $form.after('<div class="success-message">' + data + '</div>');
                    } else if (data.errorMessage) {
                        $form.after('<div class="error-message">' + data.errorMessage + '</div>');
                    }
                    counterClicks++;
                    localStorage.setItem('smsCounter', counterClicks);
                }
            });
        } else {
            $btnSubmit.attr("disabled", true);
            jQuery('.sms__limit--warning').show();
            $error.remove();
            $success.remove();
        }

    });

    /** Retina images */
    if (window.devicePixelRatio > 1) {
        var lowresImages = jQuery('img');
        lowresImages.each(function () {
            var lowres = jQuery(this).attr('src');
            var highres = lowres.replace(".", "@2x.");
            jQuery(this).attr('src', highres);
        });
    }

    /** Banner animations */
    var movementStrength = 25;
    var width = movementStrength / $(window).width();
    jQuery(".form-actions__section").mousemove(function (e) {
        var pageX = e.pageX - (jQuery(window).width() / 2);
        var newvalueX = width * pageX * -1 - 35;
        jQuery('body').css("background-position", newvalueX + "px -130px");
    });
    jQuery(".get-started__section").mousemove(function (e) {
        var pageX = e.pageX - (jQuery(window).width() / 2);
        var newvalueX = width * pageX * -1 - 35;
        jQuery('#site-main').css("background-position", newvalueX + "px bottom");
    });

    /** Chrome detecting */
    if (!!window.chrome) {
        jQuery('body').addClass('chrome');
    }

    /** Textarea height */
    var $commentArea = jQuery('#comment-area');
    $commentArea.on('focus', function () {
        jQuery(this).addClass('focused');
    });
    $commentArea.on('blur', function () {
        var self = jQuery(this);
        if(self.val().length === 0) {
            self.removeClass('focused');
        }
    });


});